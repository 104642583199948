
.hero-container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.hero-image.active {
  opacity: 1;
}

.hero-text-container {
  color: #fff;
  font-size: 20px;
  position: relative;
  z-index: 1;
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  font-family: 'Roboto', sans-serif; /* Custom font */
}

.hero-text-container:hover {
  opacity: 1;
}

.hero-text-container.active {
  opacity: 1;
}

.hero-text-container.inactive {
  opacity: 0;
}

.hero-heading {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.7); /* Slightly darker grey with transparency */
  margin: auto;
  margin-bottom: 1rem;
  display: inline-block;
  padding: 1rem 1.5rem; /* More padding for better spacing */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
  transition: transform 0.3s ease-in-out; /* Animation for hover effect */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for better readability */
}

.hero-heading:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.hero-btns {
  position: relative;
  z-index: 1; /* Ensure the button is above the images */
  margin-top: 20px;
}

.box-shadow {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
  
  .hero-container > p {
    margin-top: 8px;
    text-align: center;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }

    .hero-heading {
      font-size: 1.5rem;
    }

    .cards__header {
      font-size: 1.4rem;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }