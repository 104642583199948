.navbar {
  background: linear-gradient(90deg, rgb(1, 102, 179, 255) 50%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  content: url("/public/images/newlogo.png");
  max-width: 280px;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 50vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
  position: relative;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  transform: scale(1.1);
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-item{
    height:60px
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: start;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    content: url("/public/images/newlogo.png");
    max-width: 280px;
    position: absolute;
    left: 3%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .dropdown-menu {
    display: none; /* Hide the dropdown menu on smaller screens */
  }
}

@media screen and (max-width: 590px) {
  .NavbarItems {
    position: relative;
  }

  .navbar {
    background: linear-gradient(90deg, rgb(1, 102, 179, 255) 50%, rgb(1, 102, 179, 255) 100%);
  }
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 80px;
  left: 0;
  background-color: #242222;
  width: 200px;
  list-style: none;
  text-align: start;
  z-index: 999;
}

.dropdown-menu .dropdown-link {
  color: #fff;
  padding: 1rem;
  text-decoration: none;
  display: block;
  font-size: 0.9rem; /* Adjust font size */
  transition: all 0.2s ease-out;
}

.dropdown-menu .dropdown-link:hover {
  background-color: #1888ff;
  transform: scale(1.05);
  transition: all 0.2s ease-out;
}

@media screen and (min-width: 961px) {
  .dropdown-menu {
    display: none;
  }

  .nav-item:hover .dropdown-menu {
    display: block;
  }
}
