.vegmerkingar-container {
    position: relative;
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .vegmerkingar-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .vegmerkingar-text-container {
    color: #fff;
    font-size: 20px;
    position: relative;
    z-index: 1;
    text-align: center;
    font-family: 'Roboto', sans-serif; /* Custom font */
  }
  
  .vegmerkingar-heading {
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    margin: auto;
    margin-bottom: 1rem;
    display: inline-block;
    padding: 1rem 1.5rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .vegmerkingar-heading:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  .vegmerkingar-btns {
    position: relative;
    z-index: 1; /* Ensure the button is above the images */
    margin-top: 20px;
  }
  
  .box-shadow {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  }
  
  .vegmerkingar-container > p {
    margin-top: 8px;
    text-align: center;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .vegmerkingar-btns {
    margin-top: 32px;
  }
  
  .vegmerkingar-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .vegmerkingar-container > h1 {
      font-size: 70px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .vegmerkingar-container > h1 {
      font-size: 50px;
    }
  
    .vegmerkingar-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
  