.contact-container {
  background-size: cover;
  background-position: center;
  min-height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  font-size: 32px; 
  text-align: center;
}

.contact-header {
  margin-top: 32px;
  margin-bottom: 32px;
  border-top: solid;
  border-bottom: solid;
  padding: 24px 4rem;
  color: lightgray;
  border-width: 1px;
}

.contact-header h1,
.contact-header p {
  color: #252e48;
}

.input-areas {
  width: 100%;
  max-width: 900px;
  background-color: #5b5b5b70;
  padding-bottom: 2rem;
  border-radius: 20px;
  margin-top: 2rem;
}

.input-areas > .btn.btn--outline.btn--medium {
  max-width: 100px
}

.input-areas form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.form-input {
  width: 100%;
  max-width: 80%;
  padding: 12px;
  margin-bottom: 16px;
  border: 2px solid #3d3d3d;
  border-radius: 5px;
  font-size: 18px;
}

.form-input::placeholder {
  color: #b1b1b1;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--outline:hover {
  background: #fff;
  color: #242424;
  transition: 250ms;
}

@media screen and (max-width: 768px) {
  .contact-container {
    padding: 0;
  }

  .contact-header {
    margin-bottom: 0;
    border: 0;
    font-size: 1.4rem;
    padding: 1.5rem;
  }

  .contact-header > h1 {
    margin-bottom: 0.9rem
  }

  .input-areas {
    width: 90%;
    margin-top: 1rem;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  .btn.btn--outline.btn--medium {
    width: 80%;
  }

}
