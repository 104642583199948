.cards {
    padding: 4rem;
    background: #fff;
    padding-bottom: 2rem;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }

  .cards__box {
    margin-top: 32px;
    margin-bottom: 32px;
    border-top: solid;
    border-bottom: solid;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 24px;
    padding-bottom: 32px;
    color: lightgray;
    border-width: 1px;
  }

  .cards__box > h1 {
    color: #252e48
  }

  .cards__box > h2 {
    color: #252e48
  }


  .cards__text {
    margin-top: 32px;
    text-align: center;
  }

  .cards__text {
    color: #252e48
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
    margin-top: 4rem;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__logo-section {
    padding: 2rem 0;
    text-align: center;
    border-color: lightgray;
    padding-bottom: 5rem;
    margin-bottom: 4rem;
    border-width: 1px;
  }
  
  .cards__logo-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .cards__logo-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc; /* Thin grey outline */
    border-radius: 10px; /* Slightly rounded borders */
    overflow: hidden; /* Ensures that the image fits within the rounded corners */
    padding: 5px; /* Optional padding for spacing */
  }
  
  .cards__logo-item img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image fits within the container without stretching */
    transition: transform 0.3s ease-in-out;
    border-radius: 10px; /* Ensures the image itself has rounded corners */
  }
  
  .cards__logo-item:hover img {
    transform: scale(1.05);
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: 768px) {
    .cards__box {
      padding-left: 0;
      padding-right:0;
    }

    .cards {
      padding: 2rem;
    }

    .cards__logo-grid {
      grid-template-columns: repeat(2, 1fr);
    }

    .cards__logo-section {
      padding-bottom: 0;
      margin-bottom: 1rem;
    }
  }