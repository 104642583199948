.gangbrautarkerfi {
    padding: 4rem;
    background: #fff;
    padding-bottom: 2rem;
  }
  

  .gangbrautarkerfi__content {
    color: #252e48;
    margin-top: 42px;
    text-align: left;
    font-size: 1.7rem;
  }
  

  .gangbrautarkerfi {
    padding: 20px;
  }
  
  .gangbrautarkerfi__box {
    margin-bottom: 20px;
  }
  
  .gangbrautarkerfi__content {
    margin-bottom: 10px;
  }
  
  .gangbrautarkerfi__images {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
  }
  
  .gangbrautarkerfi__image {
    width: 32%;
    height: auto;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .gangbrautarkerfi__box {
    margin-top: 32px;
    margin-bottom: 32px;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: lightgray;
  }

  .fade-in {
    opacity: 1;
  }


  .gangbrautarkerfi__text {
    margin-top: 32px;
    text-align: center;
  }

  .gangbrautarkerfi__text {
    color: #252e48
  }
  
  .gangbrautarkerfi__wrapper {
    position: relative;
    margin: 50px 0 45px;
    margin-top: 4rem;
  }
  
  .gangbrautarkerfi__items {
    margin-bottom: 24px;
  }
  
  .gangbrautarkerfi__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  
  .gangbrautarkerfi__logo-item:hover img {
    transform: scale(1.05);
  }

  @media screen and (max-width: 768px) {
    .gangbrautarkerfi__box {
      padding-left: 0;
      padding-right:0;
    }
    .gangbrautarkerfi-heading {
      font-size: 1.5rem;
    }
  
    .gangbrautarkerfi__box {
      padding:0;
      margin:0;
    }
  
    .gangbrautarkerfi__image {
      width: 95%;
      margin-bottom: 1rem;
    }
  
    .gangbrautarkerfi__images {
      margin-top: 2rem;
      flex-direction: column;
      align-items: center;
    }
  
    .gangbrautarkerfi__content {
      font-size: 1.2rem;
  }
}